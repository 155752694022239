import logo from './logo.svg';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'nes.css/css/nes.css';
import "@fontsource/press-start-2p";
import { NavBar } from "./components/NavBar";
import { Banner } from "./components/Banner";
import { Skills } from "./components/Skills";
import { Projects } from "./components/Projects";
import { Publications } from './components/Publications';
import { Contact } from "./components/Contact";
import { Footer } from "./components/Footer";
import { ProgrammingLanguages } from './components/ProgrammingLanguages';
import { Timeline } from './components/Timeline';

function App() {
  return (
    
    <div className="App">
      <NavBar />
      <Banner />
      {/* <Skills />
      <Projects />
      <Contact /> */}
      <Publications />
      {/* <ProgrammingLanguages/> */}
      {/* <Skills /> */}
      <Timeline/>
      <Projects />
      <div >
        
      </div>

      
      
      <Footer />
    </div>
  );
}

export default App;
