import { shaderMaterial } from '@react-three/drei';
export const cellularNoiseShader = shaderMaterial(
  {
    u_time: { value: 0.0 },
    u_resolution: { value: new Float32Array([1, 1]) }, // Simple initialization
  },
  `
  varying vec2 vUv;
  void main() {
    vUv = uv;
    gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
  }`,
  `
  precision mediump float;
  varying vec2 vUv;
  void main() {
    gl_FragColor = vec4(vUv.x, vUv.y, 0.5, 1.0); // Simple color output
  }`
);
  