import { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import headerImg from "../assets/img/header-img.svg";
import { ArrowRightCircle } from 'react-bootstrap-icons';
import 'animate.css';
import TrackVisibility from 'react-on-screen';

import profile from "../assets/img/Profile.png";

import { AnacondaOriginal, AndroidOriginal, AngularOriginal, AngularjsOriginal,ArduinoOriginal,AmazonwebservicesPlainWordmark,COriginal,CplusplusOriginal,CsharpOriginal, Css3Original,DjangoPlain,DotNetOriginal,GodotOriginal,Html5Original,JavaOriginal,JavascriptOriginal,JupyterOriginalWordmark,MicrosoftsqlserverOriginalWordmark,MysqlOriginalWordmark,NodejsOriginalWordmark,NumpyLineWordmark,OpenalOriginal,PhotonengineOriginal,PhpOriginal,PostgresqlOriginal,PythonOriginal,PytorchOriginalWordmark,ReachOriginal, TensorflowLineWordmark, ThreejsOriginalWordmark,UnityOriginal,UnrealengineOriginalWordmark,VisualbasicOriginal, VisualstudioOriginal, VscodeOriginal } from 'devicons-react';


          

// import { CellularNoiseBackground, PhysarumBackground } from './CellularNoiseBackground';

export const Banner = () => {
  const [loopNum, setLoopNum] = useState(0);
  const [isDeleting, setIsDeleting] = useState(false);
  const [text, setText] = useState('');
  const [delta, setDelta] = useState(300 - Math.random() * 100);
  const [index, setIndex] = useState(1);
  const toRotate = [ "Graphics Programmer", "VR Developer", "Researcher", "ML Enthusiast" ];
  const period = 2000;

  const gridStyle = {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, minmax(100px, 1fr))', // Automatically fill based on screen size
    gap: '5px', // Space between grid items
    justifyItems: 'center', // Center items horizontally
    alignItems: 'center', // Center items vertically
    padding: '5px',
  };

  useEffect(() => {
    document.title = 'Michael Nelson - Portfolio';
    let ticker = setInterval(() => {
      tick();
    }, delta);

    return () => { clearInterval(ticker) };
  }, [text])

  const tick = () => {
    let i = loopNum % toRotate.length;
    let fullText = toRotate[i];
    let updatedText = isDeleting ? fullText.substring(0, text.length - 1) : fullText.substring(0, text.length + 1);

    setText(updatedText);

    if (isDeleting) {
      setDelta(prevDelta => prevDelta / 2);
    }

    if (!isDeleting && updatedText === fullText) {
      setIsDeleting(true);
      setIndex(prevIndex => prevIndex - 1);
      setDelta(period);
    } else if (isDeleting && updatedText === '') {
      setIsDeleting(false);
      setLoopNum(loopNum + 1);
      setIndex(1);
      setDelta(500);
    } else {
      setIndex(prevIndex => prevIndex + 1);
    }
  }

  // const ConnectButton = () => {
  //   const handleConnect = () => {
  //     window.location.href = 'mailto:michael@michaelnelson.me?subject=Let’s Connect';
  //   };

  return (
    <section className="banner" id="home">
      <div className="banner-container">
        {/* Left Column */}
        <div className="left-column">
          <h1>
            Hi! I'm Mike
            <br />
            <span
            className="txt-rotate"
            
            data-rotate='[ "Graphics Programmer", "VR Developer", "Researcher", "ML Enthusiast" ]'
          >
            <span className="wrap">{text}</span>
          </span>
          </h1>
          <p>
            I am a 4th year Ph.D. candidate in the Department of Computer Graphics Technology. Currently, I work under the advice of{' '}
            <a href="https://web.ics.purdue.edu/~cmousas/">Dr. Christos Mousas</a> and work as a simulation developer at
            The <a href="https://www.ecshowcase.com/">Envision Center</a>.
          </p>
          <p>
            I hold Bachelor's degrees in Computer Science and History from{' '}
            <a href="https://msu.edu/">Michigan State University</a> and a Master's Degree in Computer Graphics Technology from{' '}
            <a href="https://www.purdue.edu/">Purdue University</a>.
          </p>
          <p>
            My research interests include Virtual Reality, Games, Human-Computer Interaction, and Machine Learning. In my Ph.D, I focus on
            understanding and implementing novel solutions for locomotion within virtual environments.
          </p>
          <button
            onClick={() =>
              (window.location.href = 'mailto:michael@michaelnelson.me?subject=Let’s Connect')
            }
          >
            Let’s Connect <ArrowRightCircle size={25} />
          </button>
        </div>

        {/* Center Column */}
        <div className="center-column">
          <img src={profile} alt="Profile" className="profile-img" />
        </div>

        {/* Right Column */}
        <div className="right-column">
          <div className="skills">
            <h2>Programming Languages & Technologies</h2>
            <div style={gridStyle}>
              <AnacondaOriginal color="green" size="20" />
              <AndroidOriginal color="green" size="20" />
              <AngularOriginal color="green" size="20" />
              <AngularjsOriginal color="green" size="20" />
              <ArduinoOriginal color="green" size="20" />
              <AmazonwebservicesPlainWordmark color="green" size="20" />
              <COriginal color="green" size="20" />
              <CplusplusOriginal color="green" size="20" />
              <CsharpOriginal color="green" size="20" />
              <Css3Original color="green" size="20" />
              <DjangoPlain color="green" size="20" />
              <DotNetOriginal color="green" size="20" />
              <GodotOriginal color="green" size="20" />
              <Html5Original color="green" size="20" />
              <JavaOriginal color="green" size="20" />
              <JavascriptOriginal color="green" size="20" />
              <JupyterOriginalWordmark color="green" size="20" />
              <MicrosoftsqlserverOriginalWordmark color="green" size="20" />
              <MysqlOriginalWordmark color="green" size="20" />
              <NodejsOriginalWordmark color="green" size="20" />
              <NumpyLineWordmark color="green" size="20" />
              <OpenalOriginal color="green" size="20" />
              <PhotonengineOriginal color="green" size="20" />
              <PhpOriginal color="green" size="20" />
              <PostgresqlOriginal color="green" size="20" />
              <PythonOriginal color="green" size="20" />
              <PytorchOriginalWordmark color="green" size="20" />
              <ReachOriginal color="green" size="20" />
              <TensorflowLineWordmark color="green" size="20" />
              <ThreejsOriginalWordmark color="green" size="20" />
              <UnityOriginal color="green" size="20" />
              <UnrealengineOriginalWordmark color="green" size="20" />
              <VisualbasicOriginal color="green" size="20" />
              <VisualstudioOriginal color="green" size="20" />
              <VscodeOriginal color="green" size="20" />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
