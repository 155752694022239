import React, { useRef, useEffect } from 'react';
import { Canvas, useFrame } from '@react-three/fiber';
import { shaderMaterial } from '@react-three/drei';
import { extend } from '@react-three/fiber';
import { cellularNoiseShader } from './cellularNoiseShader';

// Simple vertex shader
const cellvertexShader = `
  varying vec2 vUv;

  void main() {
    vUv = uv; 
    gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
  }
`;

// const phyvertexShader = `
//   vUv = uv;
//     gl_Position = vec4(position, 1.0);
// `;

const cellfragmentShader = `
  precision mediump float;
  uniform vec2 u_resolution;
uniform float u_time;

vec2 random2(vec2 p) {
    return fract(sin(vec2(dot(p, vec2(127.1, 311.7)), dot(p, vec2(269.5, 183.3)))) * 43758.5453);
}

void main() {
    vec2 st = gl_FragCoord.xy / u_resolution;
    st.x *= u_resolution.x / u_resolution.y; // Maintain aspect ratio
    vec3 color = vec3(0.0);

    // Scale
    st *= 5.0;

    // Tile the space
    vec2 i_st = floor(st);
    vec2 f_st = fract(st);

    float m_dist = 1.0; // Minimum distance

    for (int y = -1; y <= 1; y++) {
        for (int x = -1; x <= 1; x++) {
            // Neighbor place in the grid
            vec2 neighbor = vec2(float(x), float(y));

            // Random position from current + neighbor place in the grid
            vec2 point = random2(i_st + neighbor);

            // Animate the point with a smoother oscillation
            point = 0.5 + 0.5 * sin(u_time + 6.2831 * point * 0.2); // Adjust frequency

            // Vector between the pixel and the point
            vec2 diff = neighbor + point - f_st;

            // Distance to the point
            float dist = length(diff);

            // Keep the closest distance
            m_dist = min(m_dist, dist);
        }
    }

    // Draw the min distance (distance field)
    color += m_dist;

    
    vec3 color2 = vec3(0,color.g,0);
    gl_FragColor = vec4(color2, 1.0);
}

`;


// const phyfragmentShader = `
//   uniform float u_time;
// uniform vec2 u_resolution;
// uniform sampler2D u_texture;

// varying vec2 vUv;

// vec2 random2(vec2 p) {
//     return fract(sin(vec2(dot(p, vec2(127.1, 311.7)), dot(p, vec2(269.5, 183.3)))) * 43758.5453);
// }

// void main() {
//     vec2 uv = vUv;
    
//     // Read the texture where trails and agents are stored
//     vec4 trailMap = texture2D(u_texture, uv);

//     // Simulate some noise-based movement for agents
//     float t = u_time * 0.1;
//     vec2 offset = random2(uv + t) * 0.01;
//     uv += offset;

//     // Simulate agent behavior (decay and growth)
//     vec3 color = trailMap.rgb;
//     color += vec3(0.01); // Simulate growth by adding a little brightness
//     color *= 0.99; // Simulate decay by fading colors slightly

//     // Output final color
//     gl_FragColor = vec4(color, 1.0);
// }


// `;





const CellularNoiseMaterial = shaderMaterial(
  {
    u_time: { value: 0.0 },
    u_resolution: { value: new Float32Array([window.innerWidth, window.innerHeight]) },
    
  },
  cellvertexShader,
  cellfragmentShader
);

extend({ CellularNoiseMaterial });

function CellularNoisePlane() {
  const materialRef = useRef();

  useFrame(({ clock }) => {
    if (materialRef.current) {
      materialRef.current.uniforms.u_time.value = clock.getElapsedTime();
    }
    
  });

  useEffect(() => {
    if (materialRef.current) {
      materialRef.current.uniforms.u_resolution.value = new Float32Array([window.innerWidth, window.innerHeight]);
    }

    const handleResize = () => {
      if (materialRef.current) {
        materialRef.current.uniforms.u_resolution.value = new Float32Array([window.innerWidth, window.innerHeight]);
      }
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <mesh>
      {/* Use PlaneGeometry instead of PlaneBufferGeometry */}
      <planeGeometry args={[2, 2]} />
      <cellularNoiseMaterial ref={materialRef} attach="material" />
    </mesh>
  );
}

// const PhysarumMaterial = shaderMaterial(
//     {
//       u_time: 0.0,
//       u_resolution: { value: new Float32Array([window.innerWidth, window.innerHeight]) },
//       u_texture: null
//     },
//     phyvertexShader,  // Replace with your vertex shader string
//     phyfragmentShader // Replace with your fragment shader string
//   );
  
//   extend({ PhysarumMaterial });
  
//   function PhysarumPlane() {
//     const materialRef = useRef();
  
//     useFrame(({ clock }) => {
//       if (materialRef.current) {
//         materialRef.current.uniforms.u_time.value = clock.getElapsedTime();
//       }
//     });
  
//     useEffect(() => {
//       const handleResize = () => {
//         if (materialRef.current) {
//           materialRef.current.uniforms.u_resolution.value.set(window.innerWidth, window.innerHeight);
//         }
//       };
  
//       window.addEventListener('resize', handleResize);
//       return () => window.removeEventListener('resize', handleResize);
//     }, []);
  
//     return (
//       <mesh>
//         <planeGeometry args={[2, 2]} />
//         <physarumMaterial ref={materialRef} attach="material" />
//       </mesh>
//     );
//   }

export function CellularNoiseBackground() {
  return (
    <Canvas style={{ position: 'absolute', bottom: 0, left: 0, width: '100%', height: '1750px', zIndex: -20 }} camera={{ position: [0, 0, 1], fov: 75 }}>
      <CellularNoisePlane />
    </Canvas>
  );
}

// export function PhysarumBackground() {
//     return (
//       <Canvas style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', zIndex: -1 }} camera={{ position: [0, 0, 1], fov: 75 }}>
//         <PhysarumPlane />
//       </Canvas>
//     );
//   }
