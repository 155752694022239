import { Col } from "react-bootstrap";

export const PublicationCard = ({ title, conference, abstract, imgUrl, externURL }) => {
  return (
    <div className="publication-box" >
          <img src={imgUrl} alt={title} className="publication-image" />
          <div className="publication-content">
            <h3>{title}</h3>
            <p><strong>{conference}</strong></p>
            {/* <p className="abstract">{abstract}</p> */}
          </div>
          <div className="publication-link">
            <a href={externURL} target="_blank" rel="noopener noreferrer">Read More</a>
          </div>
        </div> 

 

  )
}
