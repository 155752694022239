import { Container, Row, Col } from "react-bootstrap";
import { MailchimpForm } from "./MailchimpForm";
import logo from "../assets/img/Dragon.png";
import navIcon1 from "../assets/img/nav-icon1.svg";
import navIcon2 from "../assets/img/nav-icon2.svg";
import navIcon3 from "../assets/img/nav-icon3.svg";
import github from '../assets/img/github.png';
import linkedin from '../assets/img/linkedin.png';
import instagram from '../assets/img/insta.png';
import rg from "../assets/img/ResearchGate_icon2.png"

import { CellularNoiseBackground,PhysarumBackground } from './CellularNoiseBackground';

export const Footer = () => {
  return (
    <div className="superFooter">
      <CellularNoiseBackground />
      <footer className="footer">
      
      <Container>
        <Row className="align-items-center">
        
          {/* <MailchimpForm /> */}
          
          <Col size={12} sm={6}>
            <img src={logo} alt="Logo" />
          </Col>
          <Col size={12} sm={6} className="text-center text-sm-end">
         
          
              <div className="social-icon icon-list">
                <a href="https://github.com/MikeGNelson"><img src={github} style={{width: 40, height:40 }}></img></a>
                <a href="https://www.linkedin.com/in/michaelgeorgenelson/"><img src={linkedin} style={{width: 40, height:40 }}></img></a>
                <a href="https://www.instagram.com/michael.g.nelson/"><img src={instagram} style={{width: 40, height:40 }}></img></a>
                <a href="https://www.researchgate.net/profile/Michael-Nelson-41"><img src={rg} style={{width: 40, height:40 }}></img></a>
              </div>
              
            <p>Copyright Michael Nelson 2024</p>
          </Col>
        </Row>
      </Container>
    </footer>
    </div>
   
  )
}
