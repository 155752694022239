import { useState, useEffect } from "react";
import { Navbar, Nav, Container } from "react-bootstrap";
import logo from '../assets/img/Dragon.png';
import github from '../assets/img/github.png';
import linkedin from '../assets/img/linkedin.png';
import instagram from '../assets/img/insta.png';
import rg from "../assets/img/ResearchGate_icon2.png"
import navIcon1 from '../assets/img/nav-icon1.svg';
import navIcon2 from '../assets/img/nav-icon2.svg';
import navIcon3 from '../assets/img/nav-icon3.svg';
import { HashLink } from 'react-router-hash-link';
// import { CellularNoiseBackground } from './CellularNoiseBackground';
import {
  BrowserRouter as Router
} from "react-router-dom";

export const NavBar = () => {

  const [activeLink, setActiveLink] = useState('home');
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const onScroll = () => {
      if (window.scrollY > 50) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    }

    window.addEventListener("scroll", onScroll);

    return () => window.removeEventListener("scroll", onScroll);
  }, [])

  const onUpdateActiveLink = (value) => {
    setActiveLink(value);
  }

  return (
    <Router>
      
      <Navbar expand="md" className={scrolled ? "scrolled" : ""}>
      
        <Container>
          <Navbar.Brand href="/">
            <img src={logo} alt="Logo" />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav">
            <span className="navbar-toggler-icon"></span>
          </Navbar.Toggle>
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ms-auto">
              <Nav.Link href="#home" className={activeLink === 'home' ? 'active navbar-link' : 'navbar-link'} onClick={() => onUpdateActiveLink('home')}>Home</Nav.Link>
              <Nav.Link href="#skills" className={activeLink === 'skills' ? 'active navbar-link' : 'navbar-link'} onClick={() => onUpdateActiveLink('skills')}>Skills</Nav.Link>
              <Nav.Link href="#publications" className={activeLink === 'publications' ? 'active navbar-link' : 'navbar-link'} onClick={() => onUpdateActiveLink('publications')}>Publications</Nav.Link>
              <Nav.Link href="#projects" className={activeLink === 'projects' ? 'active navbar-link' : 'navbar-link'} onClick={() => onUpdateActiveLink('projects')}>Projects</Nav.Link>
              <Nav.Link href="#jobs" className={activeLink === 'jobs' ? 'active navbar-link' : 'navbar-link'} onClick={() => onUpdateActiveLink('jobs')}>Experience</Nav.Link>
            </Nav>
            <span className="navbar-text">
              <div className="social-icon icon-list">
                <a href="https://github.com/MikeGNelson"><img src={github} style={{width: 40, height:40 }}></img></a>
                <a href="https://www.linkedin.com/in/michaelgeorgenelson/"><img src={linkedin} style={{width: 40, height:40 }}></img></a>
                <a href="https://www.instagram.com/michael.g.nelson/"><img src={instagram} style={{width: 40, height:40 }}></img></a>
                <a href="https://www.researchgate.net/profile/Michael-Nelson-41"><img src={rg} style={{width: 40, height:40 }}></img></a>
              </div>
              {/* <HashLink to='#connect'>
                
                  <button className="vdd "><span>Let’s Connect</span></button>
               
                
              </HashLink> */}
            </span>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </Router>
  )
}
