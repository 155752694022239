
import React, { useState } from 'react';
import { Container, Row, Col, Tab, Nav } from "react-bootstrap";


const jobs = [
  { title: "Simulation Engineer", company: "Purdue Envision Center", start: "2019-05-01", end: "Present",
    description: "Worked on simulation applications for Mobile and Desktop VR, Desktop, and Websites.",
    languages: ["C#", "Python", "JavaScript"],
   },
  { title: "Teaching Assistant", company: "Purdue University", start: "2022-01-01", end: "2023-12-31",
    description: "Taught and Developed course material on Game Design, Project Management, Professional Development, and Web Design",
    languages: [], },
  { title: "Research Assistant", company: "Purdue University", start: "2019-05-01", end: "2019-08-31",
    description: "Researched Locomotion behaviours in Virtual Reality.",
    languages: ["C#", "Unity", "Python"], },
  { title: "Developer", company: "Urban Science", start: "2017-08-01", end: "2018-12-31",
    description: "Work with team of developers to create new features for angular and MVC and ASP .NET products Collaborated in the development of frameworks for running a suite of automated tests and automated deployments of various projects to reduce technical debt Work with Angular and Cordova to develop mobile integrated web technologies  Mentor interns in the development of new features and technical debt reduction Guides MSU Capstone teams to develop proof of concept applications for Urban Science Represented Urban Science in recruiting MSU students",
    languages: ["C#", "Angular", "ASP.NET", "MSSQL"], },
  { title: "Application Developer", company: "Urban Science", start: "2015-05-01", end: "2017-08-31",
    description: "Work with team of developers to create new features for angular and MVC and ASP .NET products Collaborated in the development of frameworks for running a suite of automated tests and automated deployments of various projects to reduce technical debt Work with Angular and Cordova to develop mobile integrated web technologies  Mentor interns in the development of new features and technical debt reduction Guides MSU Capstone teams to develop proof of concept applications for Urban Science Represented Urban Science in recruiting MSU students",
    languages: ["C#", "Angular", "ASP.NET", "MSSQL"], },
  { title: "Project Manager", company: "Cube2Cube", start: "2014-06-01", end: "2015-05-31",
    description: "Managed a team of five to develop a website and games for language learning Used HTML, CSS, and PHP for website creation Used Unity3D and C# for game development",
    languages: ["C#", "Unity", "PHP", "HTML", "CSS"], },
  { title: "Web Developer", company: "Global Observatory for Ecosystem Services", start: "2014-05-01", end: "2014-08-31",
    description: "Developer on a Django website for a contract with the Indian Government Used Python and Javascript for development Systems Administration / IT work",
    languages: ["Django", "Python", "JavaScript"], },
  { title: "Website Administrator", company: "Michigan State Engineering Career Center", start: "2013-06-01", end: "2014-05-31",
    description: "Maintained websites for MSU's Engineering Career Center using Durpal to manage and update. Wrote code in PHP and HTML for the sites.",
    languages: ["PHP", "HTML",], },
  { title: "Intern", company: "The Baldwin Center", start: "2012-05-01", end: "2012-08-31",
    description: "Intern / Teachers aide for a non-profit organization that administers a summer youth program. \n\n Taught elementary and middle school math and game design to underprivileged youths",
    languages: ["HTML", "JavaScript"], }

];

export const Timeline = () => {
  const [hoveredIndex, setHoveredIndex] = useState(null);
  return (
    

    <div className='banner no-padding'>
      <h1 id="jobs">Work Experience</h1>
    <div className="timeline ">
    {jobs.map((job, index) => (
        <div
          key={index}
          className="timeline-item"
          onMouseDown={() => setHoveredIndex(index)}
          // onMouseLeave={() => setHoveredIndex(null)}
        >
          <div className="timeline-content">
            <h3>{job.title}</h3>
            <h4>{job.company}</h4>
            <p>{job.start} - {job.end}</p>
            {hoveredIndex === index && (
              <div className="job-details">
                <p>{job.description}</p>
                <p className='green'>Languages: {job.languages.join(', ')}</p>
              </div>
            )}
          </div>
          <div className="timeline-dot"></div>
          {index < jobs.length - 1 && <div className="timeline-line"></div>}
        </div>
      ))}
    </div>
    </div>
  );
};


