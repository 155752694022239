import { Container, Row, Col, Tab, Nav } from "react-bootstrap";
import { ProjectCard } from "./ProjectCard";
import {PublicationCard} from "./PublicationCard"
import projImg1 from "../assets/img/publications/virtualcrowd.png";
import projImg2 from "../assets/img/project-img2.png";
import projImg3 from "../assets/img/project-img3.png";
import colorSharp2 from "../assets/img/color-sharp2.png";

import blindness from "../assets/img/publications/blindness.jpg"
import crowd1 from "../assets/img/publications/virtualcrowd.png";
import crowd2 from "../assets/img/publications/crowd2.png";
import crowd25 from "../assets/img/publications/crowd2_5.jpg";
import crowd3 from "../assets/img/publications/crowd3.png";
import crowd4 from "../assets/img/publications/crowd4.jpg";

import avoid from "../assets/img/publications/avoidance.png";
import avoid1 from "../assets/img/publications/avoidance1.png";
import avoid2 from "../assets/img/publications/avoidance2.png";

import skateboard1 from "../assets/img/publications/teaser.jpg"

import 'animate.css';
import TrackVisibility from 'react-on-screen';

export const Publications = () => {

  const projects = [
    {
      title: "Business Startup",
      description: "Design & Development",
      imgUrl: projImg1,
      externURL: "#",
    },
    {
      title: "Business Startup",
      description: "Design & Development",
      imgUrl: projImg2,
      externURL: "#",
    },
    {
      title: "Business Startup",
      description: "Design & Development",
      imgUrl: projImg3,
      externURL: "#",
    },
    {
      title: "Business Startup",
      description: "Design & Development",
      imgUrl: projImg1,
      externURL: "#",
    },
    {
      title: "Business Startup",
      description: "Design & Development",
      imgUrl: projImg2,
      externURL: "#",
    },
    {
      title: "Business Startup",
      description: "Design & Development",
      imgUrl: projImg3,
      externURL: "#",
    },
    {
        title: "Business Startup",
        description: "Design & Development",
        imgUrl: projImg1,
        externURL: "#",
      },
      {
        title: "Business Startup",
        description: "Design & Development",
        imgUrl: projImg2,
        externURL: "#",
      },
      {
        title: "Business Startup",
        description: "Design & Development",
        imgUrl: projImg3,
        externURL: "#",
      },
  ];

  const publications = [
    
    {
      title: "Comparing Control Schemas in Isometric Balanced-based Controllers",
      conference: "2024 IEEE International Symposium on Mixed and Augmented Reality (ISMAR-Adjunct)",
      abstract: "ASDASDASDASD ADSASDASD ASDASDASD",
      imgUrl: skateboard1,
      externURL: "#",
    },
    {
      title: "Avoiding Virtual Characters: The Effects of Proximity and Gesture",
      conference: "2024 IEEE International Symposium on Mixed and Augmented Reality (ISMAR)",
      abstract: "ASDASDASDASD ADSASDASD ASDASDASD",
      imgUrl: avoid2,
      externURL: "#",
    },
    {
      title: "Effects of Speed of a Collocated Virtual Walker and Proximity Toward a Static Virtual Character on Avoidance Movement Behavior",
      conference: "2023 IEEE International Symposium on Mixed and Augmented Reality (ISMAR)",
      abstract: "ASDASDASDASD ADSASDASD ASDASDASD",
      imgUrl: avoid1,
      externURL: "https://www.researchgate.net/publication/376221524_Effects_of_Speed_of_a_Collocated_Virtual_Walker_and_Proximity_Toward_a_Static_Virtual_Character_on_Avoidance_Movement_Behavior",
    },
    {
      title: "Effects of Rendering Styles of a Virtual Character on Avoidance Movement Behavior",
      conference: "2022 IEEE International Symposium on Mixed and Augmented Reality Adjunct (ISMAR-Adjunct)",
      imgUrl: avoid,
      abstract: "ASDASDASDASD ADSASDASD ASDASDASD",
      externURL: "https://www.researchgate.net/publication/366336618_Effects_of_Rendering_Styles_of_a_Virtual_Character_on_Avoidance_Movement_Behavior",
    },
    {
      title: "Walking in a Crowd Full of Virtual Characters: Effects of Virtual Character Appearance on Human Movement Behavior",
      conference: "2020 Advances in Visual Computing",
      imgUrl: crowd4,
      abstract: "ASDASDASDASD ADSASDASD ASDASDASD",
      externURL: "https://www.researchgate.net/publication/347610869_Walking_in_a_Crowd_Full_of_Virtual_Characters_Effects_of_Virtual_Character_Appearance_on_Human_Movement_Behavior",
    },
    {
      title: "A Virtual Reality Framework for Human-Virtual Crowd Interaction Studies",
      conference: "2020 IEEE International Conference on Artificial Intelligence and Virtual Reality (AIVR)",
      imgUrl: crowd3,
      abstract: "ASDASDASDASD ADSASDASD ASDASDASD",
      externURL: "https://www.researchgate.net/publication/348538810_A_Virtual_Reality_Framework_for_Human-Virtual_Crowd_Interaction_Studies?_sg%5B0%5D=glOWhn8q53to-I7C8x0KMON_-TFWKCrcX99TJQS90MbQAJrN1WWso9U3cZWQiYgCBKzaN3IE5Vi-s9OAfA0_N3CUapQg7UoIddJqMiwW.G0uHwdOIEZZ8fbVT-PgSYGz4ZWW01PmtPxEzs9mMYrkTSNBImjyoy5_D9Nt-fvOniEYD6cycakeiJUb5cLDl_A&_tp=eyJjb250ZXh0Ijp7ImZpcnN0UGFnZSI6InByb2ZpbGUiLCJwYWdlIjoicHJvZmlsZSIsInBvc2l0aW9uIjoicGFnZUNvbnRlbnQifX0",
    },
    {
      title: "Evaluating Human Movement Coordination During Immersive Walking in a Virtual Crowd",
      conference: "2020 Behavioral Sciences",
      imgUrl: crowd25,
      abstract: "ASDASDASDASD ADSASDASD ASDASDASD",
      externURL: "https://www.researchgate.net/publication/341823423_Immersive_walking_in_a_virtual_crowd_The_effects_of_the_density_speed_and_direction_of_a_virtual_crowd_on_human_movement_behavior",
    },
    {
        title: "Immersive walking in a virtual crowd: The effects of the density, speed, and direction of a virtual crowd on human movement behavior",
        conference: "2020 Computer Animation and Virtual Worlds",
        imgUrl: crowd2,
        abstract: "ASDASDASDASD ADSASDASD ASDASDASD",
        externURL: "https://www.researchgate.net/publication/341823423_Immersive_walking_in_a_virtual_crowd_The_effects_of_the_density_speed_and_direction_of_a_virtual_crowd_on_human_movement_behavior",
      },
      {
        title: "Blindness Visualizer: A Simulated Navigation Experience",
        conference: "2020 IEEE Conference on Virtual Reality and 3D User Interfaces Abstracts and Workshops (VRW)",
        imgUrl: blindness,
        abstract: "ASDASDASDASD ADSASDASD ASDASDASD",
        externURL: "https://www.researchgate.net/publication/341439955_Blindness_Visualizer_A_Simulated_Navigation_Experience",
      },
      {
        title: "Within a Virtual Crowd: Exploring Human Movement Behavior during Immersive Virtual Crowd Interaction",
        conference: "2019 The 17th International Conference on Virtual-Reality Continuum and its Applications in Industry",
        imgUrl: crowd1,
        abstract: "This paper presents an exploratory study aiming at investigating the movement behavior of participants when immersed within a virtual crowd. Specifically, a crosswalk scenario was created in which a virtual crowd was scripted to cross the road once the traffic light turned green. Participants were also instructed to walk across the road to the opposite sidewalk. During that time, the assessment of participant movement behavior was captured by the use of objective measurements (time, speed, and deviation). Five density conditions (no density, low density, medium density, high density, and extreme density) were developed to investigate which had the greatest effect on the movement behavior of the participants. The results obtained indicated that the extreme density condition of the virtual crowd did indeed alter the movement behavior of participants to a significant degree. Given that density had the greatest effect on the movement behavior of participants, a follow-up study was also conducted that utilized the density findings and explored whether density can affect the speed and direction of participants. This was achieved through examining five speed conditions and six directional conditions. The follow-up study provided some evidence that during an extreme density condition the speed of the crowd also affects the movement behavior of participants. However, no alteration in human movement behavior was observed when examining the direction of the virtual crowd. Implications for future research are discussed.",
        externURL: "https://www.researchgate.net/publication/337263410_Within_a_Virtual_Crowd_Exploring_Human_Movement_Behavior_during_Immersive_Virtual_Crowd_Interaction",
      },
  ];

  return (
    <section className="project" id="project">
      <Container>
        <Row>
          <Col size={12}>
            <TrackVisibility>
              {({ isVisible }) =>
              <div className={isVisible ? "animate__animated animate__fadeIn": ""}>
                <h2 id="publications">Publications</h2>
                {/* <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p> */}
                <Tab.Container id="projects-tabs" defaultActiveKey="first">
                  <Nav variant="pills" className="nav-pills mb-5 justify-content-center align-items-center" id="pills-tab">
                   
                  </Nav>
                  <Tab.Content  >
                    <Tab.Pane eventKey="first">
                      
                        {
                          publications.map((publication, index) => {
                            return (
                              
                                <PublicationCard
                                key={index}
                                {...publication}
                                
                                ></PublicationCard>
                                
                              
                            )
                          })
                        }
                      
                    </Tab.Pane>
                    
                  </Tab.Content>
                </Tab.Container>

                
              </div>}
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
      <img className="background-image-right" src={colorSharp2}></img>
    </section>
  )
}
